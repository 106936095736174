import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const CoachZammadWidget = ({ style, className }) => {
  const userDetails = useSelector((state) => state.authReducer.loggedInUser);

  const defaultStyle = {
    // backgroundColor: 'rgb(19, 204, 19)',
    zIndex: 1111,
    // color: 'rgb(255, 255, 255)',
    borderRadius: '30px 8px 30px 30px',
    ...style,
  };

  function activeZammadEmailBox() {
    const { firstName, lastName, email } = userDetails;

    addTitleFieldInZammadFeedbackForm();

    const zammadEmailInput = document.getElementById('zammad-form-email-modal');
    const zammadNameInput = document.getElementById('zammad-form-name-modal');

    zammadNameInput.value = lastName ? `${firstName} ${lastName}` : firstName;
    zammadEmailInput.value = email;

    zammadEmailInput.style.display = 'none';
    zammadEmailInput.parentElement.children[0].style.display = 'none';
  }

  function addTitleFieldInZammadFeedbackForm() {
    const div = document.createElement('div');
    div.className = 'form-group zammad-form-group';

    const label = document.createElement('label');
    label.textContent = 'Subject';
    label.setAttribute('for', 'zammad-form-title-modal');

    const input = document.createElement('input');
    input.type = 'text';
    input.id = 'zammad-form-title-modal';
    input.className = 'form-control zammad-form-control';
    input.placeholder = 'Enter the Subject of your ticket';
    input.name = 'title';

    div.appendChild(label);
    div.appendChild(input);

    const form = document.querySelector('.zammad-form');

    form.insertBefore(div, form.children[1]);
  }

  useEffect(() => {
    const { email } = userDetails;

    if (!email) return;
    if (window.$('#zammad-feedback-form').ZammadForm) {
      window.$('#zammad-feedback-form').ZammadForm({
        messageTitle: email,
        messageSubmit: 'Submit',
        messageThankYou:
          "This ticket has been raised (#%s)! We'll contact you as soon as possible.",
        modal: true,
        attachmentSupport: true,
      });
    }
  }, []);

  return (
    <section id='zammad-feedback-form' onClick={activeZammadEmailBox}>
      <div
        className={`${className}  cursor-pointer  w-auto whitespace-nowrap flex items-center justify-center text-gray-400 font-medium`}
        style={defaultStyle}
      >
        <HelpOutlineIcon className='mr-1' style={{ fontSize: 18 }} />
        <p className='  whitespace-nowrap select-none overflow-hidden  '>Need help?</p>
      </div>
    </section>
  );
};
