import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import AuthRouter from './modules/auth/AuthRouter';
import { COLOR_THEME, PATH } from './constants';
import Layout from './Layout';
import { setColorTheme } from './redux/colorModeSlice';
import LoggedInApp from './LoggedInApp';

const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID || '', null, options);

  const dispatch = useDispatch();
  const colorTheme = useSelector((state) => state.colorModeReducer.colorTheme);
  const theme = createTheme(colorTheme);

  useEffect(() => {
    localStorage.setItem('color-theme', 'light');
    dispatch(setColorTheme(COLOR_THEME.LIGHT));
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <div className='w-full min-h-screen max-h-screen font-montserrat dark:bg-ab-primary dark:text-ab-tertiary overflow-x-hidden relative'>
        <Router>
          <Layout>
            <Switch>
              <Route path={PATH.AUTH.HOME} component={AuthRouter} />
              <Route path='/' component={LoggedInApp} />
            </Switch>
          </Layout>
        </Router>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
