import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import EditProfile from './components/profile/EditProfile';
import NavigationBar from './components/header/NavigationBar';
import { fetchCoachSubTopics, loadUser } from './redux/authSlice';
import { fetchCompanies, fetchJobTitles } from './redux/workExperienceSlice';
import MentorshipRouter from './modules/bookings/MentorshipRouter';
import { PATH } from './constants';
import { fetchAllThemes } from './redux/themeSlice';
import { fetchQuestionLevels } from './redux/questionLevelsSlice';
import CoachOnBoarding from './components/coach_onboarding/coachOnboardPage';
import CoachDashboard from './components/coach_dashboard/CoachDashboard';
import CalendarActivity from './components/calendar_activity/CalendarActivity';
import { LoadingCircle } from './components/utils/Icons';
import ProjectEvaluationDashboard from './components/project_evaluation_dashboard/ProjectEvaluationDashboard';
import AlmaxAssignmentEvaluationDashboard from './components/almax_assignment_evaluation_dashboard/AlmaxAssignmentEvaluationDashboard';

import { fetchCoachingRoles } from './redux/coachExperienceSlice';

const NavRoute = ({ exact, path, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => (
      <>
        <NavigationBar />
        <Component {...props} />
      </>
    )}
  />
);

export default function LoggedInApp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, loggedInUser, unVerifiedCoach, coachRoles } = useSelector(
    (state) => state.authReducer
  );

  React.useEffect(() => {
    async function checkIfLoggedIn() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          dispatch(loadUser());
          dispatch(fetchAllThemes());
          dispatch(fetchCompanies());
          dispatch(fetchJobTitles());
          dispatch(fetchCoachSubTopics());
          dispatch(fetchQuestionLevels());
          dispatch(fetchCoachingRoles());
          return;
        }
        history.push(PATH.AUTH.LOGIN);
      } catch (e) {
        history.push(PATH.AUTH.LOGIN);
        console.error(e);
      }
    }
    checkIfLoggedIn();
  }, []);

  if (!isLoggedIn) {
    return <LoadingCircle iconStyle='w-10 h-10 absolute top-1/2 left-1/2' />;
  }

  if (isLoggedIn && loggedInUser.coachOnboarding !== true && !coachRoles) {
    return <CoachOnBoarding />;
  }

  return (
    <div>
      {!coachRoles && loggedInUser.coachOnboarding === true ? (
        <>
          <NavRoute path='/' component={CoachDashboard} />
        </>
      ) : (
        <>
          <Switch>
            <Redirect exact from={PATH.HOME} to={PATH.DASHBOARD} />
            <NavRoute path={PATH.DASHBOARD} component={CoachDashboard} />
            <NavRoute path={PATH.EDIT_PROFILE} component={EditProfile} />
            <Route path={PATH.MENTORSHIP.HOME} component={MentorshipRouter} />
            <Route path={PATH.CALENDAR_ACTIVITY(':coachRole')} component={CalendarActivity} />
            <Route
              exact
              path={PATH.PROJECT_EVALUATIONS_DASHBOARD}
              component={ProjectEvaluationDashboard}
            />
            <Route
              exact
              path={PATH.ALMAX_ASSIGNMENT_EVALUATIONS_DASHBOARD}
              component={AlmaxAssignmentEvaluationDashboard}
            />
          </Switch>
        </>
      )}
      {/* <Redirect exact from={PATH.HOME} to={PATH.AUTH.LOGIN} /> */}
    </div>
  );
}
